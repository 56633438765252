import React from "react";

const AboutMe = () => {
    return(
        <section className="about-me">
            <h2>Shubham here,</h2>
            <p>Short description of yourself and your career. Short description of yourself and your career. Short description of yourself and your career. Short description of yourself and your career.</p>
        </section>
    )
}

export default AboutMe;